<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <section :class="` ${bgClass}`">
    <h2 class="sr-only">Vår butik</h2>
    <div
      class="mx-auto max-w-6xl pb-6 pl-0 pt-4 text-base font-semibold text-supporting-black-87 antialiased md:px-0 md:pb-12 md:pt-7"
    >
      <div class="mx-auto overflow-x-auto">
        <div class="flex w-full justify-between gap-4 whitespace-nowrap">
          <NuxtLink
            id="shop-icon-dog"
            to="/sv/butik/djurtyp/hund"
            class="group ml-4 flex flex-col items-center gap-3"
            title="Visa produkter för hund"
          >
            <div
              class="min-w-24 overflow-hidden rounded-full bg-supporting-light-3"
            >
              <img
                class="rounded-full transition-transform duration-150 group-hover:scale-110"
                width="150"
                height="150"
                loading="lazy"
                alt="Icon of a dog"
                :src="dog"
              />
            </div>
            Hund
          </NuxtLink>
          <NuxtLink
            id="shop-icon-cat"
            to="/sv/butik/djurtyp/katt"
            class="group flex flex-col items-center gap-3"
            title="Visa produkter för katt"
          >
            <div
              class="min-w-24 overflow-hidden rounded-full bg-supporting-light-3"
            >
              <img
                class="rounded-full transition-transform duration-150 group-hover:scale-110"
                width="150"
                height="150"
                loading="lazy"
                alt="Icon of a cat"
                :src="cat"
              />
            </div>
            Katt
          </NuxtLink>
          <NuxtLink
            id="shop-icon-small"
            to="/sv/butik/djurtyp/gnagare"
            class="group flex flex-col items-center gap-3"
            title="Visa produkter för gnagare"
          >
            <div
              class="min-w-24 overflow-hidden rounded-full bg-supporting-light-3"
            >
              <img
                class="rounded-full transition-transform duration-150 group-hover:scale-110"
                width="150"
                height="150"
                loading="lazy"
                alt="Icon of a small animal"
                :src="small"
              />
            </div>
            Smådjur
          </NuxtLink>
          <NuxtLink
            id="shop-icon-horse"
            to="/sv/butik/djurtyp/hast"
            class="group flex flex-col items-center gap-3"
            title="Visa produkter för häst"
          >
            <div
              class="min-w-24 overflow-hidden rounded-full bg-supporting-light-3"
            >
              <img
                class="rounded-full transition-transform duration-150 group-hover:scale-110"
                width="150"
                height="150"
                loading="lazy"
                alt="Icon of a horse"
                :src="horse"
              />
            </div>
            Häst
          </NuxtLink>
          <NuxtLink
            id="shop-icon-bird"
            to="/sv/butik/djurtyp/fagel"
            class="group flex flex-col items-center gap-3 pr-4"
            title="Visa produkter för fågel"
          >
            <div
              class="min-w-24 overflow-hidden rounded-full bg-supporting-light-3"
            >
              <img
                class="rounded-full transition-transform duration-150 group-hover:scale-110"
                width="150"
                height="150"
                loading="lazy"
                alt="Icon of a bird"
                :src="bird"
              />
            </div>
            Fågel
          </NuxtLink>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import dog from '~/assets/images/animals/dog.webp?url';
import cat from '~/assets/images/animals/cat.webp?url';
import horse from '~/assets/images/animals/horse.webp?url';
import bird from '~/assets/images/animals/bird.webp?url';
import small from '~/assets/images/animals/small.webp?url';

const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const bgClass = props?.block?.backgroundColor
  ? props.block.backgroundColor
  : 'bg-white';
</script>
